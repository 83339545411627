<template>
	<div class="eventWraper" v-if="searchLists.length > 0">
		<div class="rsTitle rsTitleBg flex align_items_c justify_content_sb">
			<div class="rsTitleLeft flex align_items_c justify_content_sb">
				<div>MetaEra 热搜</div>
				<div
					class="flex align_items_c pointer"
					@click="$router.push({ path: '/search' })"
				>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
				</div>
			</div>
		</div>
		<div class="rsList eventWraper_content">
			<div
				class="rsItem flex align_items_c pointer"
				v-for="(item, index) in searchLists"
				:key="index"
				@click="
					$router.push({
						path: '/gw_detail',
						query: { id: item.id },
					})
				"
			>
				<div
					class="reItem_Left"
					:class="{
						reItem_Left_full: !(item.pic != null && item.pic.length > 0),
					}"
				>
					<div class="reItem_Left_title hovercolor">{{
						localLanguage == 2
							? item.title
								? item.title
								: item.title
							: item.title
					}}</div>
					<div class="flex">
						<div class="reItem_Left_line"></div>
						<div class="reItem_Left_time" v-time="item.release_time"></div>
					</div>
				</div>
				<div
					class="reItem_Right"
					style="display:(item.pic != null && item.pic.length > 0):contents:none ;"
				>
					<img class="rsItemImg" :src="item.pic" :key="item.pic" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { homeSearchList } from "@/api/home";
import { eventBus } from "./pc/leftTab";
export default {
	name: "",
	data() {
		return {
			allData: [],
			searchLists: [],
			localLanguage: 1,
		};
	},
	created() {
		this.getDataList();
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {
		getDataList() {
			let res = JSON.parse(sessionStorage.getItem("homeSearchListData"));
			if (res != null && res.code == 200) {
				this.allData = res.data.searchLists;
				var tmpData = [];
				tmpData = tmpData.concat(this.allData);
				this.searchLists = tmpData;
			}
			homeSearchList({})
				.then((res) => {
					sessionStorage.setItem("homeSearchListData", JSON.stringify(res));
					if (res.code == 200) {
						this.allData = res.data.searchLists;
						var tmpData = [];
						tmpData = tmpData.concat(this.allData);
						this.searchLists = tmpData;
					} else {
					}
				})
				.catch((error) => {});
		},
	},
};
</script>

<style scoped>
.rsList {
	margin-top: 0px;
}
.rsItem {
	height: 70px;
}
.reItem_Left {
	width: 148px;
}
.reItem_Left_full {
	width: 100%;
}
.reItem_Left_title {
	width: 100%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600 !important;
	line-height: 1.5;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.reItem_Left_time {
	width: 175px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;
	margin-top: 13px;
	color: #b2b2b2;
}
.reItem_Right {
	margin-left: 15px;
}
.rsItemImg {
	width: 97px;
	height: 60px;
	border-radius: 8px;
	object-fit: cover;
}
</style>
