<template>
	<div class="language_header_change align_items_c">
		<div
			class="language_header_sub align_items_c"
			:class="{ language_header_change_active: localLanguage == 0 }"
			@click="changeLanguage(0)"
		>
			<span
				class="language_header_sub_span align_items_c"
				:class="{
					language_header_sub_span_active: localLanguage == 0,
				}"
				>简</span
			>
		</div>
		<div
			class="language_header_sub align_items_c"
			:class="{ language_header_change_active: localLanguage == 1 }"
			@click="changeLanguage(1)"
		>
			<span
				class="language_header_sub_span align_items_c"
				:class="{
					language_header_sub_span_active: localLanguage == 1,
				}"
				>繁</span
			>
		</div>
		<div
			class="language_header_sub align_items_c"
			:class="{ language_header_change_active: localLanguage == 2 }"
			@click="changeLanguage(2)"
		>
			<span
				class="language_header_sub_span align_items_c"
				:class="{
					language_header_sub_span_active: localLanguage == 2,
				}"
				>EN</span
			>
		</div>
	</div>
</template>

<script>
import { eventBus } from "../pc/leftTab";
export default {
	name: "",
	created() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	data() {
		return {
			token: 0,
			active: 0,
			showHeader: true,
			localLanguage: 0,
		};
	},
	methods: {
		changeLanguage(index) {
			if (this.localLanguage == index) {
				return;
			}
			if (index == 0) {
				sessionStorage.setItem("localLanguage", "简体中文");
				if (this.localLanguage == 1) {
					this.$t2s(document.body);
				} else {
					this.$e2s(document.body, 0);
				}
				this.localLanguage = 0;
			} else if (index == 1) {
				sessionStorage.setItem("localLanguage", "繁体中文");
				if (this.localLanguage == 0) {
					this.$s2t(document.body);
				} else {
					this.$e2s(document.body, 1);
				}
				this.localLanguage = 1;
			} else {
				sessionStorage.setItem("localLanguage", "english");
				this.$s2e(document.body);
				this.localLanguage = 2;
			}
			// 通知发送
			eventBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
	},
};
</script>

<style scoped>
/* 头部 */
.language_header_change {
	width: 40px;
	height: 110px;
	background-color: #eaeaea;
	border-radius: 3px;
	padding-left: 2px;
	padding-right: 2px;
	/* display: flex; */
	align-items: center;
	opacity: 1;
	z-index: 999;
	position: fixed;
	right: 26px;
	bottom: 120px;
}
.language_header_sub {
	width: 30px;
	height: 30px;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2px;
}
.language_header_change_active {
	background-color: #ffffff;
}
.language_header_sub_span {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #767680;
}
.language_header_sub_span_active {
	color: #0056ff;
}
</style>
