<template>
	<div class="flex direction_column align_items_c">
		<div class="loginTab flex">
			<div class="active">注册</div>
		</div>
		<div class="js-login">
			<div class="js-login__form">
				<p class="js-login__phone">
					<span
						class="js-login__areaCode pointer"
						@click="showtimezone = !showtimezone"
					>
						+{{ loginForm.phone_code }}
						<i class="js-login__areaIcon"></i>
					</span>
					<login__areaCode v-if="showtimezone" @phone_code="getphone_code">
					</login__areaCode>
					<input
						type="text"
						placeholder="请输入手机号码"
						v-model="loginForm.username"
					/>
				</p>
				<p class="js-login_title"> 手机验证码 </p>
				<p class="js-login__verification">
					<input
						placeholder="请输入6位短信验证码"
						type="text"
						v-model="loginForm.code"
					/>

					<input
						value="获取手机验证码"
						type="button"
						class="js-login__captcha pointer"
						@click="tosendSms"
						v-show="show"
					/>
					<span class="js-login__captcha" v-show="!show">{{ count }} s</span>
				</p>
				<p class="js-login_title"> 密码 </p>
				<p class="js-login__psd">
					<input
						type="password"
						v-model="loginForm.password"
						name="password"
						data-vv-as="密码"
						placeholder="请输入8-24位密码"
					/>
				</p>
				<p class="js-login_title"> 确认密码 </p>
				<p class="js-login__psd">
					<input
						type="password"
						v-model="new_password2"
						placeholder="请输入8-24位确认密码"
					/>
				</p>
				<div class="js-login__agreement pointer">
					<p>
						<input type="checkbox" v-model="agree1" />
						<span class="js-login__agreement__text">
							我已阅读并接受
							<i
								class="js-login__agreement__text"
								@click="open(config.term_of_service.value)"
								style="color: #0056ff !important"
							>
								《MetaEra平台服务协议》
							</i>
						</span>
					</p>
					<p
						><input type="checkbox" v-model="agree2" />
						<span class="js-login__agreement__text">
							我已阅读并接受
							<i
								class="js-login__agreement__text"
								@click="open(config.privacy_policy.value)"
								style="color: #0056ff !important"
							>
								《MetaEra平台隐私政策》
							</i>
						</span>
					</p>
				</div>
				<button
					class="loginBtn flex align_items_c justify_content_c pointer"
					:disabled="
						loginForm.username == '' ||
						loginForm.code == '' ||
						loginForm.password == '' ||
						new_password2 == '' ||
						!agree1 ||
						!agree2
					"
					@click="register"
				>
					注 册
				</button>
				<div class="js-login_reigister">
					拥有账号？<i @click="$parent.$parent.showStatus = 1">去登录</i>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="showXy" append-to-body>
			<iframe
				v-bind:src="xyval"
				width="100%"
				height="550"
				scrolling="yes"
				frameborder="0"
			></iframe>
		</el-dialog>
	</div>
</template>

<script>
import store from "@/store";
import { sendSms, getAppConfig } from "@/api/home";
import login__areaCode from "./login__areaCode.vue";
export default {
	name: "",

	data() {
		return {
			showXy: false,
			xyval: "",
			loginForm: {
				username: "",
				code: "",
				type: "2",
				password: "",
				phone_code: "86",
			},
			agree1: false,
			agree2: false,
			new_password2: "",
			passType: "password",
			showtimezone: false,
			// 验证码
			show: true,
			timer: null,
			count: "",
			config: {},
		};
	},
	created() {
		this.getAppConfig();
	},
	methods: {
		getAppConfig() {
			getAppConfig({})
				.then((res) => {
					if (res.code == 200) {
						this.config = res.data;
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		getphone_code(val) {
			this.loginForm.phone_code = val;
			this.showtimezone = false;
		},
		tosendSms() {
			sendSms({
				phone: this.loginForm.username,
				phone_code: this.loginForm.phone_code,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					console.log(error);
					this.$message.error(error.message);
				});
		},
		register() {
			if (this.loginForm.password != this.new_password2) {
				this.$message.error("两次密码输入不一致");
			} else {
				store
					.dispatch("user/login", this.loginForm)
					.then(() => {
						this.$parent.$parent.dialogVisible = false;
						this.$PromptTip({ dialogVisible: true, showStatus: 1 });
					})
					.catch((error) => {
						this.$message.error(error.message);
					});
			}
		},
		open(url) {
			this.xyval = url;
			this.showXy = true;
		},
	},
};
</script>

<style scoped>
/* 登录注册 */
.loginTab {
	margin-top: 14px;
}

.loginTab div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.loginTab div:nth-child(2) {
	margin-left: 47px;
}

.active {
	position: relative;
	font-weight: bold;
}

.loginMain {
	margin-top: 50px;
	width: 344px;
}

.login_form {
	width: 100%;
	height: 61px;
	border-bottom: 1px solid #e1e1e1;
}

.numberarea {
	font-size: 17px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #666666;
	width: 78px;
}

.login_form input {
	width: 60%;
	height: 90%;
	border: none;
	outline: none;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}

.login_form img {
	width: 14px;
}

.login_bt {
	margin-top: 15px;
}

.login_bt div {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.loginBtn {
	width: 345px;
	height: 44px;
	background: #0056ff;
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	margin-top: 23px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	border: none;
	outline: none;
}

.loginBtn[disabled] {
	background: #8cb3ff;
	color: #ffffff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0px;
}

.otherLogin {
	margin-top: 37px;
}

.otherLogin .el-divider__text {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wxlogin {
	width: 40px;
	margin-top: 28px;
}
</style>
