<template>
	<div>
		<div class="app_alert">
			<div class="header_app_logo">
				<img src="https://zy.metaera.media/assets/images/aboutus/1024.jpg" />
			</div>
			<div class="header_app_title">
				<span
					style="
						font-size: 14px;
						font-family: PingFang SC, 微软雅黑, Helvetica Neue, Hiragino Sans GB,
							Microsoft YaHei, Arial, sans-serif;

						font-weight: 500;
						color: #000000;
					"
					>MetaEra</span
				>
				<span
					style="
						font-size: 12px;
						font-family: PingFang SC, 微软雅黑, Helvetica Neue, Hiragino Sans GB,
							Microsoft YaHei, Arial, sans-serif;
						font-weight: 400;
						color: #51565b;
					"
					>专注于香港 Web 3.0 资讯</span
				>
			</div>
			<div class="header_app_btn" @click="download">打开APP</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	created() {},
	data() {
		return {
			token: 0,
			active: 0,
		};
	},
	methods: {
		download() {
			let ua = navigator.userAgent.toLowerCase();
			//ios终端
			let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				this.olead = true;
			}
			if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || isiOS) {
				//判断是ios还是android
				window.location.href =
					"https://apps.apple.com/cn/app/meta-era/id6450677755"; // 跳AppStore下载地址
			} else if (/(Android)/i.test(navigator.userAgent)) {
				window.location.href = "https://zy.metaera.media/MetaEra100.apk"; //打开调整的地址
			} else {
				window.location.href = "https://zy.metaera.media/MetaEra100.apk"; //打开调整的地址
			}
			var that = this;
			setTimeout(function () {
				that.$router.push({
					path: "/download",
				});
			}, 1000);
		},
		gotoDownLoad() {
			this.$router.push({
				path: "/download",
			});
		},
	},
};
</script>

<style scoped>
.app_alert {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	width: 100%;
	height: 60px;
	background: #ffffff;
	box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.42);
	border-radius: 10px 10px 10px 10px;
	opacity: 1;
	z-index: 999;
	position: fixed;
	right: 0;
	bottom: 33px;
	z-index: 999;
}
.header_app_logo img {
	width: 40px;
	height: 40px;
	margin-left: 10px;
}
.header_app_title {
	margin-left: 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.header_app_btn {
	color: #0056ff;
	background: #ffffff;
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #0056ff;
	/* margin-right: 10px; */
	font-size: 14px;
	height: 32px;
	width: 75px;
	right: 10px;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	float: right;
}
.language_img {
	width: auto;
	height: 18px;
	margin-left: 2px;
	margin-right: 6px;
}
.header_app_logo img {
	width: 40px;
	height: 40px;
	margin-left: 10px;
}
.header_app_title {
	margin-left: 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
</style>
