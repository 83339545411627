<template>
	<div class="login">
		<el-dialog
			:visible.sync="dialogVisible"
			top="40px"
			width="100%"
			height="100%"
		>
			<div class="loginBox flex">
				<div class="loginBox_l"> </div>
				<div class="loginBox_r">
					<div class="closeBtn pointer" @click="dialogVisible = false">
						<img
							src="https://zy.metaera.media/assets/images/login/login_back.png"
							alt=""
						/>
					</div>
					<div class="loginBox_r_box">
						<div v-if="showStatus == 1">
							<login></login>
						</div>
						<div v-if="showStatus == 2">
							<register></register>
						</div>
						<div v-if="showStatus == 3">
							<login_yzm></login_yzm>
						</div>
						<div v-if="showStatus == 4">
							<forgetPass></forgetPass>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import login from "./login.vue";
import register from "./register.vue";
import login_yzm from "./login_yzm.vue";
import forgetPass from "./forgetPass.vue";
export default {
	components: { login, register, login_yzm, forgetPass },
	name: "",
	data() {
		return {
			dialogVisible: false,
			showStatus: 1,
		};
	},
};
</script>

<style>
.loginBox {
	width: 1200px;
	height: 800px;
	background: #fff;
	border-radius: 12px;
	opacity: 1;
	padding: 10px;
}

.loginBox_l {
	width: 462px;
	height: 781;
	background-image: url("https://zy.metaera.media/assets/images/my/1402.png");
	background-size: 100% 100%;
	position: relative;
}

.scareBtn {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 38px;
	width: 140px;
	height: 42px;
	background: rgba(0, 0, 0, 0.44);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #979797;
	font-size: 19px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}
.login .el-dialog {
	width: fit-content !important;
}

.login .el-dialog__header {
	display: none;
}

.login .el-dialog__body {
	padding: 0;
}
.loginBox_r {
	width: 730px;
}

.loginBox_r_box {
	margin-top: 140px;
}

.closeBtn {
	margin-left: 30px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
}

.closeBtn img {
	width: 20px;
	height: 20px;
	margin: 12px 12px 0 0;
}
</style>
