// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);
import ElementUI from "element-ui";
import App from "./App";
import router from "./router";
import store from "./store";
import "element-ui/lib/theme-chalk/index.css";
import "./permission.js"; // permission control
Vue.config.productionTip = false;
Vue.use(ElementUI);
import Component from "@/components";
Vue.use(Component); // 注册自己的插件
import PromptTip from "@/components/LoginDialog";
Vue.prototype.$PromptTip = PromptTip;
import Loading from "@/utils/loading";
Vue.use(Loading);
document.dispatchEvent(new Event("render-event"));
import Time from "@/utils/time.js";
import VueMeta from "vue-meta";
Vue.use(VueMeta, { refreshOnceOnNavigation: true });

import { s2e, e2s } from "./utils/language";
Vue.directive("time", Time);
import pvuv from "@/utils/pvuv";
Vue.prototype.$pvuv = pvuv;

import "./assets/font.css";

Vue.mixin({
	mounted() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			// console.log("简体中文");
			this.$t2s(document.body);
		} else if (localLanguage == "english") {
			// console.log("english");
			this.$s2e(document.body);
		} else {
			// console.log("繁体中文");
			this.$s2t(document.body);
		}
	},
	methods: {
		$s2t(element) {
			const nodes = element.childNodes;
			var Chinese = require("chinese-s2t");
			for (let i = 0; i < nodes.length; i++) {
				const node = nodes[i];
				if (node.nodeType === Node.TEXT_NODE) {
					// 对文本节点进行繁体转换
					var Chinese = require("chinese-s2t");
					node.textContent = Chinese.s2t(node.textContent);
				} else if (node.nodeType === Node.ELEMENT_NODE) {
					// 递归处理子元素
					var cantran = node.getAttribute("prohibitTran");
					// console.log(node.getAttribute("prohibitTran"));
					if (cantran == "1") {
						this.$t2s(node);
					} else {
						this.$s2t(node);
					}
					// 处理 input 元素的 placeholder
					if (node.tagName === "INPUT" && node.placeholder) {
						node.placeholder = Chinese.s2t(node.placeholder);
					}
					// 处理 el-input 组件的 placeholder
					if (node.classList.contains("el-input")) {
						const inputElement = node.querySelector("input");
						if (inputElement && inputElement.placeholder) {
							inputElement.placeholder = Chinese.s2t(inputElement.placeholder);
						}
					}
					if (node.classList.contains("el-textarea")) {
						const inputElement = node.querySelector("textarea");
						if (inputElement && inputElement.placeholder) {
							inputElement.placeholder = Chinese.s2t(inputElement.placeholder);
						}
					}
				}
			}
		},
		$t2s(element) {
			const nodes = element.childNodes;
			var Chinese = require("chinese-s2t");
			for (let i = 0; i < nodes.length; i++) {
				const node = nodes[i];
				if (node.nodeType === Node.TEXT_NODE) {
					// 对文本节点进行繁体转换
					var Chinese = require("chinese-s2t");
					node.textContent = Chinese.t2s(node.textContent);
				} else if (node.nodeType === Node.ELEMENT_NODE) {
					// 递归处理子元素
					this.$t2s(node);
					// 处理 input 元素的 placeholder
					if (node.tagName === "INPUT" && node.placeholder) {
						node.placeholder = Chinese.t2s(node.placeholder);
					}
					// 处理 el-input 组件的 placeholder
					if (node.classList.contains("el-input")) {
						const inputElement = node.querySelector("input");
						if (inputElement && inputElement.placeholder) {
							var Chinese = require("chinese-s2t");
							inputElement.placeholder = Chinese.t2s(inputElement.placeholder);
						}
					}
					if (node.classList.contains("el-textarea")) {
						const inputElement = node.querySelector("textarea");
						if (inputElement && inputElement.placeholder) {
							var Chinese = require("chinese-s2t");
							inputElement.placeholder = Chinese.t2s(inputElement.placeholder);
						}
					}
				}
			}
		},
		$s2e(element) {
			const nodes = element.childNodes;
			var Chinese = require("chinese-s2t");
			for (let i = 0; i < nodes.length; i++) {
				const node = nodes[i];
				if (node.nodeType === Node.TEXT_NODE) {
					var Chinese = require("chinese-s2t");
					let tmp = Chinese.t2s(node.textContent);
					node.textContent = s2e(tmp);
				} else if (node.nodeType === Node.ELEMENT_NODE) {
					// 递归处理子元素
					this.$s2e(node);

					// 处理 input 元素的 placeholder
					if (node.tagName === "INPUT" && node.placeholder) {
						node.placeholder = s2e(Chinese.t2s(node.placeholder));
					}

					if (node.classList.contains("el-input")) {
						const inputElement = node.querySelector("input");
						if (inputElement && inputElement.placeholder) {
							var Chinese = require("chinese-s2t");
							inputElement.placeholder = s2e(
								Chinese.t2s(inputElement.placeholder)
							);
						}
					}

					if (node.classList.contains("el-textarea")) {
						const inputElement = node.querySelector("textarea");
						if (inputElement && inputElement.placeholder) {
							var Chinese = require("chinese-s2t");
							inputElement.placeholder = s2e(
								Chinese.t2s(inputElement.placeholder)
							);
						}
					}
				}
			}
		},
		$e2s(element, type) {
			const nodes = element.childNodes;
			var Chinese = require("chinese-s2t");
			for (let i = 0; i < nodes.length; i++) {
				const node = nodes[i];
				if (node.nodeType === Node.TEXT_NODE) {
					var Chinese = require("chinese-s2t");
					let tmp = e2s(node.textContent);
					if (type == 0) {
						node.textContent = tmp;
					} else {
						node.textContent = Chinese.s2t(tmp);
					}
				} else if (node.nodeType === Node.ELEMENT_NODE) {
					// 递归处理子元素
					this.$e2s(node, type);

					// 处理 input 元素的 placeholder
					if (node.tagName === "INPUT" && node.placeholder) {
						let tmp = e2s(node.placeholder);
						if (type == 0) {
							node.placeholder = tmp;
						} else {
							node.placeholder = Chinese.s2t(tmp);
						}
					}

					if (node.classList.contains("el-input")) {
						const inputElement = node.querySelector("input");
						if (inputElement && inputElement.placeholder) {
							let tmp = e2s(inputElement.placeholder);
							if (type == 0) {
								inputElement.placeholder = tmp;
							} else {
								inputElement.placeholder = Chinese.s2t(tmp);
							}
						}
					}
					if (node.classList.contains("el-textarea")) {
						const inputElement = node.querySelector("textarea");
						if (inputElement && inputElement.placeholder) {
							let tmp = e2s(inputElement.placeholder);
							if (type == 0) {
								inputElement.placeholder = tmp;
							} else {
								inputElement.placeholder = Chinese.s2t(tmp);
							}
						}
					}
				}
			}
		},
	},
});
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
