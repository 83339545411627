import { render, staticRenderFns } from "./footern.vue?vue&type=template&id=198c73dd&scoped=true&"
import script from "./footern.vue?vue&type=script&lang=js&"
export * from "./footern.vue?vue&type=script&lang=js&"
import style0 from "./footern.vue?vue&type=style&index=0&id=198c73dd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "198c73dd",
  null
  
)

export default component.exports