<template>
	<div class="headerg" @mouseleave="showLanguageNew(false)">
		<div
			class="pd_header_pc flex align_items_c"
			:class="{ pd_header_bg: type == 'white' }"
		>
			<div
				class="tab_logo pointer"
				@click="
					$router.push({
						path: '/',
					})
				"
			>
				<img
					v-if="type == 'black'"
					src="https://zy.metaera.media/assets/images/event/34.png"
					alt=""
				/>
				<img
					v-else
					src="https://zy.metaera.media/assets/images/logo.png"
					alt=""
					@click="toHome()"
				/>
			</div>
			<div class="align_items_c justify_content_sb tab_action">
				<div class="navList_first_pd flex align_items_c">
					<div
						class="tab_actionItem flex pointer"
						v-for="(item, index) in navList"
						:key="index"
						@click="toPath(item, index)"
						:class="{ active: currentIndex == index }"
					>
						<div
							class="tab_actionItem_top"
							:class="{ tab_actionItem_top_white: type == 'white' }"
						>
							<img :src="type == 'white' ? item.img_w : item.img" alt="" />
							<span>{{ item.name }}</span>
						</div>
						<div class="tab_actionItem_line">
							<div
								class="tab_actionItem_line_item"
								:class="{ tab_actionItem_line_item_white: type == 'white' }"
							>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab_fun">
				<div
					class="tab_fun_language"
					:class="{ tab_fun_language_white: this.type == 'white' }"
					@mouseenter="showLanguageNew(true)"
				>
					<div class="tab_fun_language_item pointer" @click="showLanguage()">
						{{
							this.localLanguage == 0
								? "简"
								: this.localLanguage == 1
								? "繁"
								: "English"
						}}
					</div>

					<div
						class="top_Language_list"
						@mouseenter="showLanguageNew(true)"
						@mouseleave="showLanguageNew(false)"
						:class="{ top_Language_list_white: this.type == 'white' }"
						v-if="showSel == 1"
					>
						<div
							class="top_Language_list_item pointer"
							@click="selLanguage(0)"
							:class="{ top_Language_list_item_white: this.type == 'white' }"
							>简体中文</div
						>
						<div
							class="top_Language_list_item pointer"
							@click="selLanguage(1)"
							:class="{ top_Language_list_item_white: this.type == 'white' }"
							>繁体中文</div
						>
						<div
							class="top_Language_list_item pointer"
							@click="selLanguage(2)"
							:class="{ top_Language_list_item_white: this.type == 'white' }"
							>English</div
						>
					</div>
				</div>

				<div class="tab_fun_search pointer">
					<img
						v-if="type == 'black'"
						src="https://zy.metaera.media/assets/images/tab/h_search.png"
						alt=""
					/>
					<img
						v-else
						src="https://zy.metaera.media/assets/images/tab/h_search_w.png"
						alt=""
						@click="toHome()"
					/>
				</div>
				<div class="js-func js-func__app header_phone_img pointer">
					<div
						class="js-func__item--app_small tab_phone_img"
						:class="{ tab_phone_img_white: type == 'white' }"
					></div>
					<div class="js-func__download">
						<div class="tab_phone_img_qr">
							<img
								src="https://zy.metaera.media/assets/images/aboutus/download.png"
								alt="MetaEra中国版App下载"
							/>
							<span class="js-func__download_des tab_phone_img_text"
								>Android &amp; iPhone</span
							>
						</div>
					</div>
				</div>
				<div
					v-if="token == 0 || token == undefined"
					class="tab_fun_login pointer"
					@click="login()"
				>
					登录
				</div>
				<div
					class="js-func js-func__user js-userBox margin0 header_mine_img pointer"
					v-else
				>
					<div
						title=""
						class="js-func__avatar js-func__avatar_small header_mine_header"
					>
						<img
							class="tab_mine_img pointer"
							v-if="
								!(token == 0 || token == undefined) && $store.state.user.avatar
							"
							:src="$store.state.user.avatar"
							alt=""
							@click="login"
						/>
						<img
							class="tab_mine_img pointer"
							v-else
							:src="
								type == 'black'
									? 'https://zy.metaera.media/assets/images/tab/tab_mine_w1.png'
									: 'https://zy.metaera.media/assets/images/tab/tab_mine_b1.png'
							"
							alt=""
							@click="login"
						/>
					</div>
					<div class="js-func__userMenu tab_user_view pointer">
						<div class="tab_user_header flex pointer">
							<div
								class="tab_user_header_img pointer"
								@click="$router.push({ path: '/my' })"
							>
								<img
									class="tab_user_img pointer"
									v-if="
										!(token == 0 || token == undefined) &&
										$store.state.user.avatar
									"
									:src="$store.state.user.avatar"
									alt=""
									@click="login"
								/>
								<img
									class="tab_user_img pointer"
									v-else
									:src="
										type == 'black'
											? 'https://zy.metaera.media/assets/images/tab/tab_mine_w1.png'
											: 'https://zy.metaera.media/assets/images/tab/tab_mine_b1.png'
									"
									alt=""
									@click="login"
								/>
							</div>
							<div class="tab_user_header_left pointer">
								<div class="tab_user_header_phone">
									{{ this.mobile }}
								</div>
								<div class="tab_user_header_name">
									{{ $store.state.user.name }}
								</div>
							</div>
						</div>
						<div class="tab_user_publish pointer" @click="gotoMy"> </div>
						<div
							class="tab_user_menu flex"
							style="margin-top: 10px"
							@click="$router.push({ path: '/my' })"
						>
							<div class="tab_user_menu_img">
								<img
									src="https://zy.metaera.media/assets/images/my/my_user_info.png"
									alt=""
								/>
							</div>
							<div class="tab_user_menu_con pointer"> 用户信息 </div>
						</div>
						<div class="tab_user_menu_line flex"> </div>
						<div
							class="tab_user_menu flex"
							@click="$router.push({ path: '/mywz' })"
						>
							<div class="tab_user_menu_img">
								<img
									src="https://zy.metaera.media/assets/images/my/my_user_attention.png"
									alt=""
								/>
							</div>
							<div class="tab_user_menu_con pointer"> 我的文章 </div>
						</div>

						<div class="tab_user_menu_line flex"> </div>
						<div
							class="tab_user_menu flex"
							@click="$router.push({ path: '/ac_mine' })"
						>
							<div class="tab_user_menu_img">
								<img
									src="https://zy.metaera.media/assets/images/my/my_user_event.png"
									alt=""
								/>
							</div>
							<div class="tab_user_menu_con pointer">活动参与 </div>
						</div>

						<div class="tab_user_menu_line flex"> </div>
						<div
							class="tab_user_menu flex"
							@click="$router.push({ path: '/ac_organ' })"
						>
							<div class="tab_user_menu_img">
								<img
									src="https://zy.metaera.media/assets/images/my/my_user_organ.png"
									alt=""
								/>
							</div>
							<div class="tab_user_menu_con pointer">活动主办 </div>
						</div>
						<div class="tab_user_menu_line flex"> </div>
						<div class="tab_user_logout flex pointer" @click="logOut"
							>退出登录</div
						>
					</div>
				</div>
			</div>
		</div>
		<div
			class="pd_header_mobile flex align_items_c"
			:class="{ pd_header_bg: type == 'white' }"
		>
			<div class="tab_logo">
				<img
					src="https://zy.metaera.media/assets/images/event/34.png"
					@click="
						$router.push({
							path: '/',
						})
					"
					alt=""
				/>
			</div>
			<div class="tab_btn">
				<img
					src="https://zy.metaera.media/assets/images/event/48.png"
					@click="showList()"
					alt=""
				/>
			</div>
		</div>

		<div class="pd_header_list" v-show="isShowList">
			<div class="pd_header_list_btn" @click="hideList()">
				<img src="https://zy.metaera.media/assets/images/event/42.png" alt="" />
			</div>
			<div class="tab_actionItem_line_2"></div>
			<div class="pd_header_list_1">
				<div
					class="tab_actionItem_pd flex pointer"
					v-for="(item, index) in navList"
					:key="index"
					@click="toPath(item, index)"
					:class="{ active: currentPath == item.router }"
				>
					<span>{{ item.name }}</span>
					<div class="tab_actionItem_line_1"></div>
					<div class="tab_actionItem_line_3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { getToken, getUserMobile } from "@/utils/auth";
import Vue from "vue";
export const newBus = new Vue();
export default {
	name: "",
	props: {
		type: {
			type: String,
			default: "black",
		},
		currentPath: {
			type: String,
			default: "/polkadot2024",
		},
	},
	mounted() {
		// 包含关系
		if (location.href.indexOf("home_kuaixun") != -1) {
			this.currentIndex = 1;
		} else if (location.href.indexOf("home_zl") != -1) {
			this.currentIndex = 2;
		} else if (
			location.href.indexOf("activity") != -1 ||
			location.href.indexOf("events") != -1
		) {
			this.currentIndex = 3;
		} else if (
			location.href.indexOf("/") == -1 ||
			location.href.indexOf("home") != -1
		) {
			this.currentIndex = 0;
		} else {
			this.currentIndex = 999;
		}

		this.showSel = 0;

		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	created() {
		this.token = getToken();
		this.mobile = getUserMobile();
		this.$router.beforeEach((to, from, next) => {
			this.keyword = "";
			next();
		});
	},
	data() {
		return {
			token: 0,
			mobile: "",
			keyword: "",
			currentIndex: 0,
			isShowList: false,
			localLanguage: 0,
			showSel: 1,
			navList: [
				{
					name: "首页",
					img: "https://zy.metaera.media/assets/images/tab/h_home.png",
					img_w: "https://zy.metaera.media/assets/images/tab/h_home_w.png",
					router: "/",
				},
				{
					name: "快讯",
					img: "https://zy.metaera.media/assets/images/tab/h_flash.png",
					img_w: "https://zy.metaera.media/assets/images/tab/h_flash_w.png",
					router: "/home_kuaixun",
				},
				{
					name: "专栏",
					img: "https://zy.metaera.media/assets/images/tab/h_special.png",
					img_w: "https://zy.metaera.media/assets/images/tab/h_special_w.png",
					router: "/home_zl",
				},
				{
					name: "活动",
					img: "https://zy.metaera.media/assets/images/tab/h_active.png",
					img_w: "https://zy.metaera.media/assets/images/tab/h_active_w.png",
					router: "/events",
				},
			],
		};
	},
	methods: {
		showLanguageNew(show) {
			this.showSel = show ? 1 : 0;
		},
		showLanguage() {
			this.showSel = this.showSel == 1 ? 0 : 1;
			console.log(this.showSel);
		},
		selLanguage(index) {
			this.showSel = 0;
			this.localLanguage = index;
			console.log(index);

			if (index == 0) {
				sessionStorage.setItem("localLanguage", "简体中文");
				if (this.localLanguage == 1) {
					this.$t2s(document.body);
				} else {
					this.$e2s(document.body, 0);
				}
			} else if (index == 1) {
				sessionStorage.setItem("localLanguage", "繁体中文");
				if (this.localLanguage == 0) {
					this.$s2t(document.body);
				} else {
					this.$e2s(document.body, 1);
				}
			} else {
				sessionStorage.setItem("localLanguage", "english");
				this.$s2e(document.body);
			}
			// 通知发送
			newBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
		login() {
			if (this.token == 0 || this.token == undefined) {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			} else {
				this.$router.push({ path: "/my" });
			}
		},

		logOut() {
			store.dispatch("user/logout");
		},
		gotoMy() {
			const hasToken = getToken();
			// console.log(hasToken);
			if (hasToken) {
				this.$router.push({ path: "/publish" });
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		toPath(item, index) {
			console.log(index);
			this.currentIndex = index;
			this.currentPath = item.router;
			this.$router.push({ path: item.router });
			this.isShowList = false;
		},
		showList() {
			console.log("12312312");
			this.isShowList = true;
		},
		hideList() {
			this.isShowList = false;
		},
		showUrl() {
			window.open("https://lu.ma/hszom0hw", "");
		},
	},
	watch: {
		"$route.path": function (n) {
			this.showHeader = n == "/";
			this.currentPath = n;
			if (n == "/" || n == "/home") {
				this.currentIndex = 0;
			} else if (n == "/home_kuaixun") {
				this.currentIndex = 1;
			} else if (n == "/home_zl") {
				this.currentIndex = 2;
			} else if (n == "/activity" || n == "/events") {
				this.currentIndex = 3;
			} else {
				this.currentIndex = 999;
			}
		},
	},
};
</script>

<style scoped>
.headerg {
	height: 100px;
	background: black;
	z-index: 100;
	position: fixed;
}
.pd_header_bg {
	background-color: white !important;
	color: white;
}
.pd_header_mobile {
	display: none;
}
.pd_header_pc {
	display: flex;
	top: 0px;
	left: 0;
	width: calc(100vw);
	height: 100px;
	margin: 0 auto;
	z-index: 100;
	position: fixed;

	background: black;
}

.tab_action {
	width: 100%;
	text-align: center;
	/* align-content: center; */
	/* align-items: center; */
	/* background: red; */
}

.navList_first_pd {
	width: clamp(500px, calc(100vw * 500 / 1280), 800px);
	/* background: orange; */
	display: inline-block;
	text-align: center;
	/* align-content: center; */
	/* align-items: center; */
}
.tab_actionItem {
	width: auto;
	margin-left: calc(100vw * 20 / 1280);
	margin-right: calc(100vw * 20 / 1280);
	height: 50px;
	display: flex;
	flex-direction: column;
	display: inline-block;
	align-content: center;
	align-items: center;
	text-align: center;
}
.tab_actionItem_top {
	height: 50px;
	display: flex;
	align-items: center;
}
.tab_actionItem_top img {
	width: calc(100vw * 20 / 1280);
	min-width: 20px;
	max-width: 30px;
	height: calc(100vw * 20 / 1280);
	min-height: 20px;
	max-height: 30px;
}
.tab_actionItem span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(16px, calc(100vw * 16 / 1280), 2rem);
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	/* 纯白 */
	color: #ffffff;
}

.tab_actionItem_top_white span {
	color: #333333;
}

.tab_actionItem .tab_actionItem_line {
	display: none;
}

.navList_first_pd .active span {
	font-family: DINPro-Bold;
	font-weight: bold;
}

.navList_first_pd .active .tab_actionItem_line {
	position: absolute;
	display: flex;
	margin-top: 6px;
	width: 100%;
	height: 2px;
}
.tab_actionItem_line_item {
	width: calc(100vw * 20 / 1280);
	min-width: 20px;
	max-width: 30px;
	background: white;
}
.tab_actionItem_line_item_white {
	background: #333333;
}
.tab_logo {
	position: absolute;
	margin-left: clamp(100px, calc(100vw * 100 / 1280), 140px);
}

.tab_logo img {
	height: clamp(20px, calc(100vw * 20 / 1280), 40px);
	max-height: 40px;
}

.tab_btn {
	position: absolute;
	right: 50px;
}

.tab_btn img {
	height: 45px;
}

.tab_fun {
	position: absolute;
	display: flex;
	right: clamp(20px, calc(100vw * 60 / 1280), 100px);
	align-items: center;
}
.tab_fun img {
	width: calc(100vw * 20 / 1280);
	min-width: 20px;
	max-width: 30px;
	height: calc(100vw * 20 / 1280);
	min-height: 20px;
	max-height: 30px;
	opacity: 1;
}

.tab_fun_language {
	align-content: center;
	border-radius: 6px;
	height: 30px;
	margin-right: 20px;
	padding-left: 10px;
	padding-right: 10px;
	background: rgba(255, 255, 255, 0.2);
	color: #ffffff;
}

.tab_fun_language_item {
}

.tab_fun_language_white {
	background: rgba(0, 0, 0, 0.2);
	color: black;
}
.top_Language_list {
	position: absolute; /* 设置绝对定位 */
	top: 60px; /* 将浮层放置在父元素下方 */
	left: 0; /* 左对齐 */
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* 阴影效果 */
	z-index: 1000; /* 确保浮层在其他元素之上 */
	padding-top: 20px;
	display: flex;
	position: absolute;
	justify-content: center;
	flex-direction: column;
	bottom: 160px;
	/* width: 90px; */
	height: 100px;
	border-radius: 10px;
	opacity: 1;
	background: #333333;
}

.top_Language_list_white {
	background: rgba(0, 0, 0, 0.7);
}
.top_Language_list_item {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	color: #aaaaaa;
	margin-bottom: 20px;
	margin-left: 10px;
	margin-right: 10px;
}

.top_Language_list_item_white {
	color: white;
}
.top_Language_list_item:hover {
	font-weight: 600;
	color: #ffffff;
}

.top_Language_list_sel {
	color: #ffffff;
}

.tab_fun_login {
	width: calc(100vw * 100 / 1280);
	max-width: 120px;
	height: calc(100vw * 42 / 1280);
	max-height: 50px;
	border-radius: 64px;
	opacity: 1;
	background: #0056ff;
	font-family: Inter;
	font-size: clamp(16px, calc(100vw * 16 / 1280), 2rem);
	font-weight: normal;
	line-height: 0px;
	text-align: center;
	align-items: center;
	align-content: center;
	letter-spacing: 0em;

	color: #ffffff;
}

.tab_phone_img {
	width: calc(100vw * 20 / 1280);
	min-width: 20px;
	max-width: 30px;
	height: calc(100vw * 20 / 1280);
	min-height: 20px;
	max-height: 30px;
	background-size: 100%;
	background-image: url("https://zy.metaera.media/assets/images/tab/h_download.png") !important;
}

.tab_phone_img_white {
	background-image: url("https://zy.metaera.media/assets/images/tab/h_download_w.png") !important;
}
.tab_phone_img_qr img {
	width: 100px !important;
	height: 100px !important;
	max-width: 100px !important;
	max-height: 100px !important;
}
.tab_phone_img_text {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	color: #1f1f22;
	display: inline-block;
	text-align: center;
	padding-top: 5px;
}

.header_phone_img {
	margin-right: calc(100vw * 40 / 1280);
}
.tab_fun_search {
	margin-right: calc(100vw * 20 / 1280);
}
</style>

<style scoped>
.header_mine_img {
}
.header_mine_header,
.tab_mine_img {
	width: calc(100vw * 30 / 1280) !important;
	min-width: 20px;
	max-width: 40px !important;
	height: calc(100vw * 30 / 1280) !important;
	min-height: 20px;
	max-height: 40px !important;
	/* background: #767676; */
	border-radius: 10px;
}
.tab_noLogin {
	position: absolute;
	right: 40px;
	top: 36px;
}

.tab_user_view {
	left: -90px !important;
}
.tab_user_header_img {
	margin-left: 7px;
	margin-top: 7px;
}
.tab_user_img {
	width: 40px;
	height: 40px;
	border-radius: 12px;
}
.tab_user_header_left {
	margin-left: 10px;
	margin-top: 7px;
	height: 40px;
}

.tab_user_header_phone {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 14px;
	letter-spacing: 0em;
	margin-top: 4px;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.tab_user_header_name {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	margin-top: 6px;
	/* 描述字段 */
	color: #656878;
}

.tab_user_publish {
	background-image: url("https://zy.metaera.media/assets/images/zl/zl_publish.png");
	background-size: cover;
	margin-top: 20px;
	width: 200px;
	height: 48px;
	margin-left: 7px;
}

.tab_user_menu {
	height: 38px;
}
.tab_user_menu_img {
	margin-left: 10px;
	margin-top: 10px;
}

.tab_user_menu_img img {
	width: 14px;
	height: 14px;
}

.tab_user_menu_con {
	margin-top: 18px;
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: center;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.tab_user_menu_line {
	margin-left: 10px;
	width: 200px;
	height: 1px;
	opacity: 0.4;
	background: #ededed;
}

.tab_user_logout {
	margin-left: 10px;
	margin-top: 20px;
	width: 200px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	justify-content: center;
	align-items: center;
	/* 主色（一级颜色） */
	color: #0056ff;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.headerg {
		height: 50px;
	}
	.headergscrolled {
		background-color: #333333 !important;
	}
	.pd_header_mobile {
		display: flex;
		height: 50px;
		top: 0px;
		left: 0;
		width: calc(100vw);
		margin: 0 auto;
		z-index: 100;
		position: fixed;
		background: black;
	}
	.pd_header_pc {
		display: none;
	}

	.tab_logo {
		margin-left: -0px;
		width: 100%;
		text-align: center;
	}

	.tab_logo img {
		height: 20px;
	}
	.tab_btn {
		position: absolute;
		left: 20px;
		right: 80%;
	}

	.tab_btn img {
		height: 20px;
	}
	.pd_header_list {
		width: 100%;
		height: 100vh;
		background: white;
		display: flex;
		flex-direction: column;
		top: 0px;
		left: 0;
		margin: 0 auto;
		z-index: 100;
		position: fixed;
	}
	.pd_header_list_btn {
		width: 20px;
		height: 20px;
		margin-top: 15px;
		margin-left: calc(100vw * 0.9);
	}
	.pd_header_list_btn img {
		width: 100%;
		height: 100%;
	}
	.tab_actionItem_line_2 {
		width: 100%;
		height: 1px;
		margin-top: 30px;
		/* margin-left: 10%; */
		margin-right: 10%;
		background: #e7e7e7;
	}
	.pd_header_list_1 {
		margin-top: 20px;
	}
	.tab_actionItem_pd {
		width: 90vw;
		height: 60px;
		margin-left: 5vw;
		display: flex;
		flex-direction: column;
	}
	.tab_actionItem_pd span {
		font-family: D-DIN;
		font-size: 18px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #999999;
	}

	.pd_header_list_1 .tab_actionItem_line_3 {
		display: none;
	}

	.pd_header_list_1 .active span {
		font-family: DINPro-Bold;
		font-weight: bold;
		color: #333333;
	}

	.pd_header_list_1 .active .tab_actionItem_line_3 {
		display: flex;
		width: 60px;
		height: 2px;
		margin-top: -1px;
		margin-right: 10%;
		background: #333333;
	}

	.tab_actionItem_line_1 {
		width: 100%;
		height: 1px;
		margin-top: 20px;
		margin-right: 10%;
		background: #e7e7e7;
	}
}
</style>
